import React, { useEffect, useState } from "react";
import $ from "jquery";
import HeaderNavMenu from "./HeaderNavMenu";
import MobileMenu from "./MobileMenu";
import { Link } from "react-router-dom";
import siteSetting from '../../data/site-setting.json';
import Products from '../../data/services.json';


const Header = () => {
  
  const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const isPopupClosed = sessionStorage.getItem("popupClosed");
  //   const isformSubmitted = sessionStorage.getItem("formSubmitted");

  //   if (isPopupClosed || isformSubmitted) {
  //     setShowPopup(false);
  //   } else {
  //     const timeout = setTimeout(() => {
  //       setShowPopup(true);
  //     }, Math.floor(Math.random() * (20000 - 10000)) + 10000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem("popupClosed", true);
  };

  const handleSubmitForm = () => {
    setShowPopup(false);
    sessionStorage.setItem("formSubmitted", true);
  };

  useEffect(() => {

    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-fixed-height").removeClass("active-height");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-fixed-height").addClass("active-height");
      }
    });
  }, []);

  return (
    <header>
      <div id="header-fixed-height" />

      <div id="sticky-header" className="menu-area menu-style-four">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img src={siteSetting.logo} alt={siteSetting.siteName} />
                    </Link>
                  </div>

                  <HeaderNavMenu />

                  {/* <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="header-contact">
                        <div className="icon">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                        <div className="content">
                          <span>Call for help:</span>
                          <a href={`tel:${siteSetting.contactnumber}`}>{siteSetting.contactnumber}</a>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                </nav>
              </div>

              <MobileMenu />
            </div>
          </div>
        </div>
      </div>


      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-body">
              <div className="product-details">
                <img src="/img/images/expo_impo.jpg" alt="" />
              </div>

              <div className="enquiry-form">
                <h5>Answer to get 10% OFF and a Product suggestion.</h5>
                <h4>What you looking for?</h4>
                <form onSubmit={handleSubmitForm}>

                  <div className="category-grid">
                    {Object.keys(Products).slice(1, 5).map((category, index) => (
                      <div className="form-group" key={index}>
                        <label htmlFor={category}>
                          <input type="checkbox" id={category} /> {category}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="form-group phone-input-group">
                    <div className="country-code-dropdown">
                      <select className="form-control">
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                      </select>
                    </div>
                    <input type="tel" className="form-control" placeholder="Phone Number" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="checkboxOption">
                      <input type="checkbox" id="offerUpdates" /> Update me for Future special offers and discounts.
                    </label>
                  </div>

                  <div className="button-group">
                    <button type="submit" className="btn small-btn">
                      Book a Call
                    </button>

                    <div className="skip-for-now-text" onClick={handleClosePopup}>
                      <p>Skip / Close for now</p>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      )}



    </header>
  );
};

export default Header;
