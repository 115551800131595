import React, { useEffect } from "react";
import AboutUs from "../components/AboutUs/AboutUs";
import BreadcrumbArea from "../components/BreadcrumbArea/BreadcrumbArea";
import Layout from "../layouts/Layout";
import { gsapTitleAnimation } from "../lib/gsap-lib/gsapTitleAnimation";
import ProjectAllMasonry from "../components/ProjectAreas/ProjectAllMasonry";


const AboutUsPage = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout>
        <BreadcrumbArea title={"About Us"} subtitle={"About Us"} />
        <AboutUs />
        <ProjectAllMasonry />
      </Layout>
    </>
  );
};

export default AboutUsPage;
