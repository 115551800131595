import { jarallax } from "jarallax";
import React, { useEffect, useState } from "react";
import { bgImgFromData } from "../../lib/helpers";
import { Link } from "react-router-dom";
import Products from '../../data/services.json'
import { handleApiRequest } from '../../lib/api-util';
import { useAlert } from "react-alert";

const Banner = () => {

  const alert = useAlert();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    enquiryFor: "",
  });

  useEffect(() => {
    bgImgFromData();
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      formData.formId = 100;
      await handleApiRequest('submit-form', 'post', formData, alert);
      // Log a success message
      console.log("Form data submitted successfully!");
    } catch (error) {
      console.error("Error submitting form data:", error);
    } finally {
      // Reset the form data after successful submission
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        enquiryFor: "",
      });
    }
  };

  const isSubmitDisabled = Object.values(formData).some((val) => val === "");

  return (
    <section
      className="banner-area-three jarallax banner-bg-three"
      data-background="/img/banner/h4_banner_bg.jpg"
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-10 order-0 order-lg-2">
            {/* <div className="banner-form-wrap">
              <h2 className="title">Request Call Back</h2>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Phone Number"
                  />
                </div>

                <div className="form-group">
                  <select
                    id="shortBy"
                    name="enquiryFor"
                    value={formData.enquiryFor}
                    onChange={handleInputChange}
                    className="form-control"
                  >
                    <option selected disabled>Enquiry for</option>
                    {Object.keys(Products).map((category, index) => (
                      <option key={index}>{category}</option>
                    ))}
                  </select>
                </div>

                <button type="submit" className="btn btn-two" disabled={isSubmitDisabled}>
                  Submit Your Request
                </button>
              </form>

            </div> */}
          </div>

          <div className="col-lg-6">
            <div className="banner-content-three">
              <h2 className="title">
                Elevate Your Home with MR Exports
              </h2>

              <p>
                Explore our exquisite range of home textiles, meticulously crafted to bring luxury and sophistication to every corner of your home.
                At MR Exports, we offer premium quality bedding, elegant dining essentials, and must-have kitchen textiles,
                all designed to enhance your living spaces. Discover the perfect blend of innovation, style,
                and excellence with our tailored collections. Upgrade your home aesthetics with MR Exports today
              </p>

              <div className="banner-btn">
                <Link to="/about" className="btn">
                  About Us
                </Link>
                <Link to="/explore-products/all" className="btn btn-two">
                  Explore Products
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
