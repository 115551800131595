import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";
import SiteSetting from '../../data/site-setting.json'
import footerLinks from '../../data/footer-links.json'
import QuickLinks from '../../data/quick-links.json'
import products from '../../data/services.json'


const Footer = () => {

  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src={SiteSetting.logo} alt={SiteSetting.siteName} />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      {SiteSetting.socialMediallength > 0 && SiteSetting.socialMedia.map((item, index) => (
                        <li key={index}>
                          <a href={item.url}>{item.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <div className="footer-content">
                    <p>{SiteSetting.address}</p>
                    <p>{SiteSetting.address2}</p>
                    {/* <div className="footer-contact">
                      <ul className="list-wrap">
                        <li className="email-addess">
                          <a href={`mailto:${SiteSetting.contactEmail}`}>{SiteSetting.contactEmail}</a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Products</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      <li>
                        {Object.keys(products).slice(0, 4).map((category, index) => (
                          <li key={index}>
                            <Link to={`/explore-products/${category.replace(/\s+/g, '-').toLowerCase()}`}>{category}</Link>
                          </li>
                        ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  {footerLinks.map((widget, index) => (
                    <div key={index}>
                      <div className="fw-title">
                        <h4 className="title">{widget.title}</h4>
                      </div>
                      <div className="fw-link-list">
                        <ul className="list-wrap">
                          {widget.links.map((link, i) => (
                            <li key={i}>
                              <Link to={link.url}>{link.text}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  {QuickLinks.map((widget, index) => (
                    <div key={index}>
                      <div className="fw-title">
                        <h4 className="title">{widget.title}</h4>
                      </div>
                      <div className="fw-link-list">
                        <ul className="list-wrap">
                          {widget.links.map((link, i) => (
                            <li key={i}>
                              <Link to={link.url}>{link.text}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          </div>
        </div>
        <FooterBottom />
      </div>
    </footer>
  );
};

export default Footer;
