import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import Products from '../../data/services.json'

const productItems = Object.keys(Products).map((category, index) => (
  <li key={index}>
    <Link to={`/explore-products/${category.replace(/\s+/g, '-').toLowerCase()}`}>{category}</Link>
  </li>
));


const HeaderNavMenu = () => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">

      <ul className="navigation">
        <li className={cn(isActiveClassName("/about"))}>
          <Link to="/about">About Us</Link>
        </li>

        <li className={cn(isActiveClassName("/facilities"))}>
          <Link to="/facilities">Facilities</Link>
        </li>

        <li className={cn(isActiveClassName("/gallery"))}>
          <Link to="/gallery">Gallery</Link>
        </li>


        <li
          className={cn("menu-item-has-children", {
            active: [
              "/explore-products"
            ].includes(pathname),
          })}
        >
          <a href="#">Products</a>
          <ul className="sub-menu">
            {productItems}
          </ul>
        </li>

        <li className={cn(isActiveClassName("/contact"))}>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>

    </div>
  );
};

export default HeaderNavMenu;
