import React, { useEffect } from "react";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import Gallery from "../../components/ProjectAreas/Gallery";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const ProjectPageTwo = () => {
  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  return (
    <>
      <Layout>
        <BreadcrumbArea title="Gallery" subtitle="Explore Gallery"/>
        <Gallery />
      </Layout>
    </>
  );
};

export default ProjectPageTwo;
