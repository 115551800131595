import React, { useEffect } from "react";
import BreadcrumbArea from "../components/BreadcrumbArea/BreadcrumbArea";
import Layout from "../layouts/Layout";
import { gsapTitleAnimation } from "../lib/gsap-lib/gsapTitleAnimation";

const Facilities = () => {
    useEffect(() => {
        gsapTitleAnimation();
    }, []);

    return (
        <Layout>
            <BreadcrumbArea title={"Facilities "} subtitle={"Facilities "} />
            <div className="privacy-policy-container" style={{ margin: '50px' }}>
                <h1>Facilities </h1>
                <b style={{ color: "black" }}>Last updated: April 16, 2024</b>
                <p>At MR Exports, we boast a state-of-the-art manufacturing facility spanning over 15,000 sq. ft., to ensure top-notch production. Our extensive array of printing capabilities, including Reactive Printing, Screen Printing, and Pigment Printing, combined with weaving expertise in Plain, Twill, Jacquard, Embroidery, Drill, and Herringbone techniques, allows us to deliver products that meet international specifications.</p>
                <p>In line with the dynamic global market, we strategically outsource certain production processes such as Dyeing, Weaving, and Printing to uphold efficiency and meet the diverse demands of our customers and buyers. Moreover, we deploy highly qualified and experienced personnel to conduct rigorous quality checks at every stage of the production process, ensuring that our products consistently meet the highest standards of excellence. At MR Exports, we are committed to delivering quality products that exceed expectations and satisfy the evolving needs of our clientele.
                </p>
            </div>
        </Layout>
    );
};

export default Facilities;
