import React from "react";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import BreadcrumbArea from "../components/BreadcrumbArea/BreadcrumbArea";

const Sitemap = () => {
    return (
        <Layout>
            <BreadcrumbArea title={"Sitemap"} subtitle={"Sitemap"} />
            <div className="sitemap-container" style={{ margin: '50px' }}>
                <ul>
                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                    <li>
                        <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                        <Link to="/explore-products/all">Products</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
        </Layout>
    );
};

export default Sitemap;
