import React from "react";
import { handleApiRequest } from '../../lib/api-util';


const ContactForm = () => {


  const handleSubmit = async (formData) => {
    try {
      formData.formId = 100;
      await handleApiRequest('submit-form', 'post', formData, alert);
      console.log("Form data submitted successfully!");
    } catch (error) {
      console.error("Error submitting form data:", error);
    } 
  };


  return (
    <form action="#" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Your Name" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input type="email" className="form-control" placeholder="Email address" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Phone number" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <input type="text" className="form-control" placeholder="Subject" />
          </div>
        </div>
      </div>
      <div className="form-group">
        <textarea name="message" rows="10" className="form-control" placeholder="Write message"></textarea>
      </div>
      <button disabled type="submit" className="btn">
        Send a message
      </button>
    </form>
  );
};

export default ContactForm;
