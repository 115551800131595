import React from "react";
import { ProjectList } from "./ProjectList";
import { Link } from "react-router-dom";

const Gallery = () => {
    return (
        <section className="inner-project-area-two pt-130 pb-130">
            <div className="container">
                <div className="project-item-wrap">
                    <div className="row justify-content-center">
                        {ProjectList.map((item, index) => (
                            <div key={index} className="col-lg-4 col-md-6 col-sm-10">
                                <div className="project-item inner-project-item">
                                    <div>
                                        <img src={item.src2} alt="" width="500" height="400" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Gallery;
