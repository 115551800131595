import React from "react";
import Odometer from "react-odometerjs";

const OdometerItem = ({ startYear }) => {

  const calculateYears = (startYear, endYear) => {
    const startDate = new Date(startYear, 0, 1);
    const endDate = new Date(endYear, 0, 1);

    const difference = endDate - startDate;

    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
    const years = Math.floor(difference / millisecondsInYear);

    return years;
  };

  const currentYear = new Date().getFullYear();
  const years = calculateYears(startYear, currentYear);

  return years;
};

export default OdometerItem;
